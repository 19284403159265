import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ButtonSp from '../../components/ButtonSp';
import CalendarSp from '../../components/CalendarSp';
import DropdownSp from '../../components/DropdownSp';
import InputCurrencySp from '../../components/InputCurrencySp';
import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import AtletaService from '../../services/AtletaService';
import { errorHandle, validateFields } from '../../util/functions';
import { AtletaModel } from '../../util/Models';
import { StateScreen } from '../constants';

interface IProps {
  stateScreen: string;
  idSelected: string;
  onClose: (ret?: any) => void;
}
export default function AtletaCrud(props: IProps) {
  const { stateScreen, idSelected, onClose } = props;

  // states
  const [atleta, setAtleta] = useState(new AtletaModel());
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  // useCallbacks
  const loadRecord = useCallback(async (_id: string) => {
    try {
      const retorno = await AtletaService.findById(_id);
      if (retorno.dataNascimento) {
        retorno.dataNascimento = new Date(retorno.dataNascimento);
      }

      retorno.data = new Date(retorno.data);

      setAtleta(retorno);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  // events

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', (idx: number) => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno: any;
      const atividadeSalvar = {
        ...atleta,
        stravaId: atleta.stravaId || null,
        reliveId: atleta.reliveId || null,
      };
      if (stateScreen === StateScreen.stInsert) {
        retorno = await AtletaService.insert(atividadeSalvar);
      } else {
        retorno = await AtletaService.update(atividadeSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new AtletaModel();

      setAtleta(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <div className="col-12 sm:col-6 lg:col-6 p-fluid">
        <LabelSp>Nome</LabelSp>
        <InputTextSp
          disabled={viewMode()}
          value={atleta.nome}
          maxLength={50}
          required
          type="text"
          onChange={(e) =>
            setAtleta({
              ...atleta,
              nome: e.target.value,
            })
          }
        />
      </div>

      <div className="col-12 sm:col-6 lg:col-6 p-fluid">
        <LabelSp>Nome Strava</LabelSp>
        <InputTextSp
          disabled={viewMode()}
          value={atleta.stravaUsername}
          maxLength={50}
          type="text"
          onChange={(e) =>
            setAtleta({
              ...atleta,
              nome: e.target.value,
            })
          }
        />
      </div>

      <div className="col-12 sm:col-3 lg:col-3 p-fluid">
        <LabelSp>Id Strava</LabelSp>
        <InputCurrencySp
          disabled={viewMode()}
          value={atleta.stravaId}
          required={!atleta.reliveId}
          digits={0}
          onChangeNumber={(_e, v) => setAtleta({ ...atleta, stravaId: v })}
        />
      </div>

      <div className="col-12 sm:col-3 lg:col-3 p-fluid">
        <LabelSp>Id Relive</LabelSp>
        <InputCurrencySp
          required={!atleta.stravaId}
          disabled={viewMode()}
          value={atleta.reliveId}
          digits={0}
          onChangeNumber={(_e, v) => setAtleta({ ...atleta, reliveId: v })}
        />
      </div>

      <div className="col-12 sm:col-3 lg:col-3 p-fluid">
        <LabelSp>Sexo</LabelSp>
        <DropdownSp
          options={[
            { label: 'Masculino', value: 'M' },
            { label: 'Feminino', value: 'A' },
          ]}
          placeholder=""
          onChange={(e) => {
            setAtleta({ ...atleta, sexo: e?.value });
          }}
          value={atleta.sexo}
        />
      </div>

      <div className="col-12 sm:col-3 lg:col-3 p-fluid">
        <LabelSp>Dt. Nascimento:</LabelSp>
        <CalendarSp
          appendTo={document.body}
          readOnlyInput
          // locale={cfgPtBr}
          id="dataFinal"
          dateFormat="dd/mm/yy"
          value={atleta.dataNascimento}
          yearNavigator
          yearRange="2010:2040"
          onChange={(e) => setAtleta({ ...atleta, dataNascimento: e.value })}
        />
      </div>

      <div className="col-12 lg:col-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            disabled={
              !validateFields(atleta, [
                'nome',
                !atleta.stravaId || atleta.reliveId ? 'stravaId' : '',
              ])
            }
            showConfirmation
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}
