/**
 * Módulo de constantes usadas na plataforma, como URL base, nome das rotas, e outros
 * @module Constantes
 * @category config
 */

/**
 * Retorna a URL base para conexão com a API
 * @returns {string}
 */
const getBaseURL = (): string => {
  if (process.env.NODE_ENV === 'development') {
    if (window.location.hostname !== '') {
      const apiPort = process.env.NODE_ENV === 'development' ? 3333 : 3333;

      // if (process.env.NODE_ENV === 'development') {
      //   return 'http://192.168.0.130:7001';
      // }

      return `${window.location.protocol}//${window.location.hostname}:${apiPort}`;

      // return 'http://teste.telessaude.speedysistemas.com.br:4955';
    }
  } else {
    return `${window.location.protocol}//analytics.speedysistemas.com.br:3333`;
  }
  return '';
};

/**
 * Retorna um objeto de configuração da API contendo a URL base e os nomes das rotas
 * @const
 */
export const ConfigApi = {
  baseURL: getBaseURL(),

  // URLs
  UsuarioURL: 'usuarios',
  apiInfoURL: 'apiInfo',
  AtividadeAtletaURL: 'atleta-atividade',
  AtletaURL: 'atletas',
};

/**
 * Tipos de filter INTEGER: 1, DECIMAL: 2, TEXT: 0, DATE: 3
 * @const filterType
 */
export const filterType = {
  INTEGER: 1,
  DECIMAL: 2,
  TEXT: 0,
  DATE: 3,
};

/**
 * Configuração para o componente de calendário
 * @const cfgPtBr
 */
export const cfgPtBr = {
  firstDayOfWeek: 0,
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['Do', 'Seg', 'Te', 'Qua', 'Qui', 'Sex', 'Sa'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  today: 'Hoje',
  clear: 'Limpa',
};

/**
 * @typedef Ufs
 * @property {string} value
 * @property {string} label
 */

/**
 * Lista de tipos de Uf no formato {label: string, value: string }
 * @const {Ufs[]} listaUfs
 */
export const listaUfs = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' },
];
