/**
 * Página Atleta
 * @module Atleta
 * @category Pages
 */

import { Column } from 'primereact/column';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import { showMessage } from '../../components/MessageDialog';
import AtletaService from '../../services/AtletaService';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
} from '../../util/functions';
import { StateScreen } from '../constants';
import AtletasAtletaCrud from './crud';
import { Container } from './styles';

export default function Atletas() {
  // useSelectors
  const filterVisible = useSelector((state: any) => state.global.filterVisible);

  // useMemo
  const domParams = useParams();
  const [domSearch] = useSearchParams();

  const pageParams = useMemo(
    () => getPageParams(domParams, domSearch),
    [domParams, domSearch]
  );

  const filterService = useMemo(() => AtletaService.getFilter(), []);

  // useStates
  const toBack = pageParams.toBack || '/atletas';

  const [filter, setFilter] = useState(filterService);
  const [atletas, setAtletas] = useState([]);

  const [pageLimit, setPageLimit] = useState<number>(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  // const [funcTimeOut, setFuncTimeOut] = useState<NodeJS.Timeout>();

  const navigation = useNavigate();

  // useCallbacks

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(9, 15, 17);
    } else {
      ret = calcNaxItemsPage(12, 16, 20);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter: any, _page = 0, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await AtletaService.findAll(_filter);
        setAtletas(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  // const setFilterAndSearch = useCallback(
  //   async (_filterValue: any) => {
  //     if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
  //       setFilter(_filterValue);

  //       if (funcTimeOut) {
  //         clearTimeout(funcTimeOut);
  //       }
  //       const func = setTimeout(async () => {
  //         handleBuscar(_filterValue);
  //       }, 800);
  //       setFuncTimeOut(func);
  //     }
  //   },
  //   [filter, funcTimeOut, handleBuscar]
  // );

  function onPage(event: any) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async (_id: number) => {
      try {
        await AtletaService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Atleta';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const confirmaExclusao = useCallback(
    (id: any) => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', (idx: any) => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o atleta digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page card">
      <div className="grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={false}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonConfig={false}
          showButtonInsert
          titleFilterDefault="Buscar por Descrição"
          handleFilterDefault={(text) => {
            filter.nome = text;
            setFilter({ ...filter, nome: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              nome: undefined,
            });
          }}
          handleButtonInsert={() => navigation('/atletas/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        />
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <div className="col-12 p-fluid datatable-doc-demo">
        <DataTableSp
          value={atletas}
          style={{ marginBottom: '2px' }}
          paginator
          rows={pageLimit}
          lazy
          totalRecords={totalRecords}
          first={first}
          onPage={(event: any) => onPage(event)}
        >
          <Column field="nome" className="grid-col" header="Nome" />
          <Column
            field="stravaUsername"
            className="grid-col"
            header="Nome Strava"
            style={{ width: 350 }}
          />

          <Column
            field="stravaId"
            className="grid-col"
            header="Id Strava"
            style={{ width: 150 }}
          />

          <Column
            field="reliveId"
            className="grid-col"
            header="Id Relive"
            style={{ width: 150 }}
          />

          <Column
            field="sexo"
            className="grid-col"
            header="Sexo"
            body={(rowData) => {
              if (!rowData.sexo) {
                return '';
              }
              return rowData.sexo === 'M' ? 'Masculino' : 'Feminino';
            }}
            style={{ width: 50 }}
          />
          <Column
            className="grid-col gid-col-acoes-35"
            bodyStyle={{ textAlign: 'end' }}
            body={renderButtonOp}
          />
        </DataTableSp>
      </div>
    );
  }

  function renderButtonOp(rowData: any) {
    return (
      <BotaoMenuGrid
        handles={[
          () => navigation(`/atletas/${rowData.id}?view`),
          () => navigation(`/atletas/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[false, false, true]}
      />
    );
  }

  function renderCrud() {
    return (
      <AtletasAtletaCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        onClose={() => {
          navigation(toBack);
          handleBuscar(filter, pageSelected, false);
        }}
      />
    );
  }
}
